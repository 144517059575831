import React from "react";
import styles from "@aspen/theme/Home.module.less";
import dynamic from "next/dynamic";
import { IFlexibleProducts, IInjectProps, IKycLevel } from "@aspen/model";
import { withRouter } from "next/router";
import {
  certifiedKycLevel,
  GA_ENTRANGE_TYPE,
  GA_EVENT_NAME,
  i18nUtil,
  reportEvent,
  YIELD_PATHS
} from "@aspen/libs";
import { isEqual } from "lodash-es";
import { CacheFlexibleSavingsTable } from "../yield/DynamicImport";
import { NotKyc2Mask } from "./DynamicImport";
import { WithEmptyContent } from "@aspen/ui";

// TODO: dynamic import
const DcaComponent = dynamic(() => import("./DcaComponent"));
const YieldComponent = dynamic(() => import("./YieldComponent"));

interface IProps extends IInjectProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
  yieldProducts: IFlexibleProducts[];
  type: "noDeposit" | "inFunds";
  showToProfileBtn?: boolean;
}

interface IState {}

class PopularSavings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.handleClickOnViewAll = this.handleClickOnViewAll.bind(this);
  }

  handleClickOnViewAll(): void {
    reportEvent({ moduleName: GA_EVENT_NAME.home.viewAllYield });
    this.props.router.push(YIELD_PATHS.YIELD);
  }

  componentDidMount(): void {}

  render(): React.ReactNode {
    const { yieldProducts, showToProfileBtn } = this.props;
    const intl = i18nUtil.t();
    return (
      <section className={styles.popularSavings}>
        {!certifiedKycLevel(1) && <NotKyc2Mask />}
        {/* @ts-ignore */}
        <div style={{ filter: certifiedKycLevel(1) ? null : "blur(2px)" }}>
          <p className={styles.recommendedTitle}> {intl["compliance.yield.title"]}</p>
          <WithEmptyContent showEmpty={true} imgWidth="100px">
            <div style={{ marginBottom: 30 }}>
              <DcaComponent />
            </div>
            <div className={styles.savings} style={{ marginBottom: "16px" }}>
              {yieldProducts
                ?.slice(0, 3)
                .map((item) => (
                  <YieldComponent
                    showGuideModal={this.props.showGuideModal}
                    yieldProductsItem={item}
                    key={JSON.stringify(item)}
                    type={this.props.type}
                    gaExtra={{ entrance: GA_ENTRANGE_TYPE.homeBottom }}
                  />
                ))}
            </div>
            <div style={{ marginBottom: 30 }}>
              <CacheFlexibleSavingsTable showToProfileBtn={showToProfileBtn} number={3} />
            </div>
          </WithEmptyContent>
          {yieldProducts?.length > 0 && (
            <p className={styles.viewAll} onClick={this.handleClickOnViewAll}>
              {intl["overview.savings.view.all"]} &gt;
            </p>
          )}
        </div>
      </section>
    );
  }

  shouldComponentUpdate(nextProps): boolean {
    return (
      !isEqual(nextProps.yieldProducts, this.props.yieldProducts) ||
      nextProps.type != this.props.type
    );
  }
}

export default withRouter(PopularSavings);
